import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/KimchiSwap/Final Kwikswap 25-12-2020/Main Repos/Beadswap/org/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Beadswap SDK exists to help developers build on top of Beadswap. It’s designed to run in any environment that can execute JavaScript (think websites, node scripts, etc.). While simple enough to use in a hackathon project, it’s also robust enough to power production applications.`}</p>
    <h1 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h1>
    <p>{`The easiest way to consume the SDK is via npm. To install it in your project, simply run `}<inlineCode parentName="p">{`yarn add @beadswap/sdk`}</inlineCode>{` (or `}<inlineCode parentName="p">{`npm install @beadswap/sdk`}</inlineCode>{`).`}</p>
    <h1 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Usage`}</h1>
    <p>{`To run code from the SDK in your application, use an `}<inlineCode parentName="p">{`import`}</inlineCode>{` or `}<inlineCode parentName="p">{`require`}</inlineCode>{` statement, depending on which your environment supports. Note that the guides following this page will use ES6 syntax.`}</p>
    <h2 {...{
      "id": "es6-import",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#es6-import",
        "aria-label": "es6 import permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ES6 (import)`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId } from '@beadswap/sdk'
console.log(\`The chainId of mainnet is \${ChainId.MAINNET}.\`)
`}</code></pre>
    <h2 {...{
      "id": "commonjs-require",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#commonjs-require",
        "aria-label": "commonjs require permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CommonJS (require)`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const BEAD = require('@beadswap/sdk')
console.log(\`The chainId of mainnet is \${BEAD.ChainId.MAINNET}.\`)
`}</code></pre>
    <h1 {...{
      "id": "reference",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#reference",
        "aria-label": "reference permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference`}</h1>
    <p>{`Comprehensive reference material for the SDK is available `}<Link to='/docs/v1/SDK/getting-started' mdxType="Link">{`here`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      